<template>
  <WhitePageLayout
    :title="$t('title')"
    :subtitle="$t('descr')"
    :page-icon="pageIcon"
  >
    <div class="container">
      <div class="v-grid ">
        <div class="col-2 col-offset">
          <StoryText>
            <template #title>
              <div v-html="$t('texts.0.title')"></div>
            </template>
            <div v-html="$t('texts.0.text')"></div>
          </StoryText>

          <StoryTechnologies :technologiesProps="technologiesArray" />
        </div>
      </div>
    </div>

    <StoryBigImg :bigImg="bigImgObject" />

    <div class="container">
      <div class="v-grid ">
        <div class="col-2 col-offset">
          <StoryText>
            <template #title>
              <div v-html="$t('texts.1.title')"></div>
            </template>
            <div v-html="$t('texts.1.text')"></div>
          </StoryText>

          <VisualizationVideo
            public-id="mediaformat-website/Video-Hausboot.mp4"
          />

          <StoryText>
            <template #title>
              <div v-html="$t('texts.2.title')"></div>
            </template>
            <div v-html="$t('texts.2.text')"></div>
          </StoryText>
        </div>
      </div>
    </div>
  </WhitePageLayout>
</template>

<script>
import StoryTechnologies from "@/components/story/StoryTechnologies";
import StoryBigImg from "@/components/story/StoryBigImg";
import StoryText from "@/components/story/StoryText";
import VisualizationVideo from "@/components/story/VisualizationVideo";

import { android, unreal, oculus } from "@/utils/technologyImages";

import DESKTOPSTORY from "@/assets/img/story/story-mit-vr-zur-bootsmesse/houseboat-image.jpg";
import MOBILESTORY from "@/assets/img/story/story-mit-vr-zur-bootsmesse/houseboat-image-MOBILE.jpg";
import { metaInfo } from "@/utils/titleHelper";
import WhitePageLayout from "@/components/layouts/WhitePageLayout";
import pageIcon from "@/assets/img/logo/cube.svg";

export default {
  name: "StoryProzesseEffizienterGestalten",
  components: {
    WhitePageLayout,
    VisualizationVideo,
    StoryTechnologies,
    StoryBigImg,
    StoryText
  },
  data() {
    return {
      isContactModal: false,
      pageIcon,
      technologiesArray: [unreal, android, oculus],
      bigImgObject: {
        desktopSrc: DESKTOPSTORY,
        mobileSrc: MOBILESTORY,
        mobileAlt: this.$t("imgAlt"),
        desktopAlt: this.$t("imgAlt")
      }
    };
  },
  metaInfo() {
    return metaInfo(this.$t("title"), this.$t("descr"));
  },
  i18n: {
    messages: {
      de: {
        title: "Mit Virtual-Reality zur Bootsmesse",
        descr: "Aus 16 Tonnen Stahl und Technik werden Bit und Bytes.",
        imgAlt: "Mit Virtual-Reality zur Bootsmesse",
        texts: [
          {
            title: "Projekt",
            text: `
          <p>
            Die Präsentation
            <strong>großer und schwer beweglicher Produkte auf Messen</strong>
            ist stets eine logistische Herausforderung, die für Unternehmen mit
            viel Aufwand und hohen Kosten verbunden ist. In unserem Beispiel
            haben wir eine
            <strong
              >digitale Lösung in Form einer Virtual-Reality Anwendung</strong
            >
            geschaffen, die von dieser Last befreien soll.
          </p>
          <p>
            Hierfür wurde eine 3D-Kopie eines Hausbootes in hoher Detailtreue
            realisiert und als Virtual-Reality-App auf Android-Basis umgesetzt.
          </p>
          <p>
            Mit Hilfe der
            <strong>mobilen Datenbrille Oculus-Quest</strong> können
            Interessenten, ohne Kabelsalat und zusätzlicher Hardware, den
            digitalen Nachbau des Hausbootes virtuell begehen und dessen
            Funktionen und Designvariationen wirklichkeitsnah erleben.
          </p>
        `
          },
          {
            title: "Herausforderung",
            text: `
          <p>
            Die besondere Herausforderung bildete dabei die Aufgabe, dem
            Benutzer ein
            <strong>realistisches und ästhetisches Raumgefühl</strong> im
            virtuellen Hausboot zu vermitteln. Für das perfekte VR- Erlebnis
            sollte ein <strong>digitaler Zwilling</strong> in millimetergenauer
            Detailarbeit in 3D entstehen. Texturen und 3D-Modelle mussten mit
            hoher Qualität überzeugen, gleichzeitig aber auch mit den
            eingeschränkten Hardware-Ressourcen mobiler Datenbrillen
            funktionieren.
          </p>
          <p>
            Durch die zusätzliche
            <strong
              >Implementierung eines benutzerfreundlichen
              Materialkonfigurators</strong
            >, sollte die Möglichkeit bestehen, das virtuelle Hausboot jederzeit
            um weitere Interior-Design Konzepte zu erweitern.
          </p>
        `
          },
          {
            title: "Ergebnis",
            text: `
          <p>
            <strong>„Geil“</strong>, <strong>„unglaublich“</strong> oder einfach
            nur <strong>„wow“</strong> – das Feedback der Benutzer, kurz nach
            dem Aufsetzen der mobilen Datenbrille.
          </p>
          <p>
            Mit diesem Projekt haben wir ein schönes Beispiel für ein
            <strong>Vertriebswerkzeug</strong> geschaffen, das ganz flexibel und
            <strong>unabhängig von Zeit oder räumlichen Einschränkungen</strong>
            eine detailgetreue, sowie wirklichkeitsnahe Produktpräsentation
            bieten kann.
          </p>
          <p>
            Das stilvolle Raumkonzept wurde von <a
              href="https://no-bla.de/projekte/crusing-home-the-onyx-edition/"
              target="_blank"
              >NoBLå Innenarchitekten</a
            > aus Karlsruhe mit viel Liebe zum Detail entworfen.
          </p>
        `
          }
        ]
      },
      en: {
        title: "Virtual reality to attend a houseboat trade fair",
        descr: "16 tons of steel transformed into bits and bytes",
        imgAlt: "Virtual reality to attend a houseboat trade fair",
        texts: [
          {
            title: "Project",
            text: `<p>Exhibiting <strong>large products that are difficult to transport at trade fairs</strong> always presents logistical challenges which require companies to invest significant time and money. <strong>Our digital solution, a virtual reality application</strong>, is specifically designed to help companies overcome this hurdle and inspire customers.
          </p>
          <p>In this Success Story, we designed a highly detailed 3D model of a houseboat then
implemented it as a virtual reality app in an Android environment.</p>
          <p>Using a <strong>wireless Oculus Quest VR headset</strong>, connected via mobile data, and no additional hardware required, trade fair attendees could walk through the digital replica of the houseboat, interact with its features and preview design variations in a realistic context. </p>
        `
          },
          {
            title: "The challenge",
            text: `<p>It is particularly challenging to convey in a virtual space both a <strong>realistic and aesthetically pleasing environment</strong>. For the perfect VR experience, a <strong>digital twin</strong> of a houseboat was crafted in 3D, with details that were precise to the millimeter. Both the 3D model as well as surface textures needed to convince with their high quality, yet work on the limited hardware of a mobile VR headset.</p>
          <p>By <strong>implementing and integrating an intuitive configuration tool</strong>, the virtual houseboat can
be extended to include additional interior design concepts at any time.</p>
        `
          },
          {
            title: "The result",
            text: `
          <p>
            <strong>"Cool"</strong>, <strong>"unbelievable"</strong> or just <strong>"wow"</strong> – were common exclamations from attendees, shortly after putting on the mobile VR headset.
          </p>
          <p>
            With this project, we have crafted a flexible and powerful <strong>sales tool</strong> to present products in
high detail and realistically, with <strong>no restrictions on time or physical space</strong>.
          </p>
          <p>The elegant space was crafted by <a
              href="https://no-bla.de/projekte/crusing-home-the-onyx-edition//"
              target="_blank"
              >NoBLå Interior Designs</a> in Karlsruhe, Germany, with great attention to detail.
          </p>
        `
          }
        ]
      }
    }
  }
};
</script>
