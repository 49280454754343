<template>
  <div>
    <div class="story-container w-100 position-relative img-desktop mb-3">
      <img
        style="max-width: 100%"
        :src="bigImg.desktopSrc"
        :alt="bigImg.desktopAlt"
      />
    </div>

    <div class="story-container img-mobile mb-3">
      <img
        style="max-width: 100%"
        :src="bigImg.mobileSrc"
        :alt="bigImg.mobileAlt"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StoryBigImg",
  props: {
    bigImg: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../styles/imports";
.story-container {
  max-width: 1490px;
  margin: 0 auto;
  padding: 0 $basePadding;
}

.img-desktop {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
.img-mobile {
  display: block;
  @include breakpoint(md) {
    display: none;
  }
}
</style>
